import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from "gatsby"
import Layout from '../components/layout';
import SEO from '../components/seo';

const Letterspost = (props) => {
  console.log(props)
  const postData = props.data.markdownRemark;
  return(
  <Layout pageId="letters" logoText="Letters" >
      <SEO title={`${postData.frontmatter.title} `} image={postData.frontmatter.featImg} />
    <div className='py-12'>
      <div className='py-24' style={{ backgroundColor:'#fafafa'}}>
        <div className='max-w-5xl grid gap-10 grid-cols-2 mx-auto'>
          <div className=''>
            <small className='uppercase text-xs block mb-2 text-gray-700 '>{postData.frontmatter.sectionName}</small>
            <h1 className='font-serifn font-medium text-4xl leading-tight text-gray-900'>{postData.frontmatter.title}</h1>
            <div className='flex text-sm mt-4'>
              <span className='font-normal text-gray-700'>{postData.frontmatter.date}</span>
              <span className='font-normal text-gray-700 ml-3'>By Athul Suresh</span>

            </div>
          </div>
          <div className=''>
            <img src={postData.frontmatter.featImg } className='rounded-lg width-full' />
          </div>
          
        </div>
      </div>


      <div className=' max-w-5xl flex justify-center mx-auto mt-12'>

          {/* <div className='w-3/12'>
            <div className='px-3 py-3  bg-gray-100 rounded-lg'>
              <span className='font-medium text-gray-600 text-xs tracking-wider'>SHARE ON</span>
              <div className='grid grid-cols-1 gap-4 mt-3'>
                <a className='flex text-sm text-gray-700 hover:text-gray-800' href="#"><span></span><span>Facebook</span></a>
              </div>
            </div>
          </div> */}

                <div className='w-9/12 pl-8'>
                   <div
                            className="letter-post-content markdown"
                            dangerouslySetInnerHTML={{ __html: postData.html }}
                        />
                </div>
      </div>
    </div>
  </Layout>)
  ;
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        featImg
        pageCode
        sectionName
        type
      }
    }
  }
`


export default Letterspost;
